.table-div {
	align-items: center;
	justify-content: space-between;
	background: rgba(255, 255, 255, 0.45);
	margin: 0px 0px 15px 0px;
}
.button-div {
	display: flex;
	gap: 10px;
	justify-content: center;
}

.model-content {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.table-content {
	gap: 10px;
}

.select-option {
	padding: 10px 5px;
	border: 1px solid #ced4da;
	border-radius: 0.375rem;
}

.btn-green-gradient {
	background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
	color: white;
	font-weight: 800;
}
.modal-backdrop {
	display: none;
}
.user-detail-body {
	height: 400px;
	overflow-y: scroll;
}

/* changes */
@font-face {
	font-family: 'Clash Display';
	src: url('../../../assets/fonts/ClashDisplay-Medium.woff2') format('woff2'),
		url('../../../assets/fonts/ClashDisplay-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

.form-control:focus {
	box-shadow: none;
}
.title-searchbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #fff;
	border-radius: 8px;
}
.title-searchbar {
	/* font-family: 'Clash Display'; */
	font-weight: 600;
}
.title-searchbar .list-search-bar {
	margin-left: auto;
	margin-right: 20px;
	margin-bottom: 0 !important;
}
.table-lists {
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
}
/* .table-lists th,
.table-lists td {
  font-family: 'Clash Display';
} */
.modal-header,
.modal-footer {
	background-color: #fff;
}
.modal-open .modal {
	background-color: rgb(0 0 0 / 50%);
}
.modal-content .modal-body td {
	border: 0;
}

.checkbox-container {
	display: flex;
	width: 100%;
	height: calc(2.25rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.checkbox-container .label {
	margin-right: 10px;
}
.btn-wrapper {
	display: flex;
	justify-content: flex-end;
	padding: 10px 0 30px;
}
.user-det {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 7px 0 20px;
}
.user-det img {
	width: 150px;
	height: 150px;
	border-radius: 50%;
}
.user-detail img {
	width: 150px;
	height: 150px;
}
.book-wrap-btn {
	display: flex;
	gap: 15px;
}
.book-wrap-btn .back-btn {
	gap: 7px;
	align-items: center;
	justify-content: center;
}
.back-btn-wrap {
	display: flex;
	padding-top: 4px;
}
.back-btn {
	gap: 7px;
	align-items: center;
	justify-content: center;
}

@media (max-width: 600px) {
	.title-searchbar {
		display: block;
	}
	.title-searchbar .itakaaf-input {
		margin-bottom: 12px;
	}
}

.responsive-heading {
	font-size: 1.25rem; /* Mobile default */
	font-weight: 600;
}

@media (min-width: 768px) {
	.responsive-heading {
		font-size: 2rem; /* Larger screens */
	}
}
