.login-page {
  width: 50%;
  padding: 8% 0 0;
  margin: auto;
  height: 100vh;
  align-items: center;
}
.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  margin: 0 auto 100px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  /* height: 65%; */
}
.login-submit{
  border: 1px solid #0e5472 !important;
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #0e5472;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button:hover,
.form button:active,
.form button:focus {
  background: #263c7e;
  color: #ffffff;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #4caf50;
  text-decoration: none;
}

.container {
  position: relative;
  z-index: 1;
  /* max-width: 300px; */
  margin: 0 auto;
}
.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #ef3b3a;
}

.img-div {
  width: 100%;
}
.img-div img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.login-form {
  padding: 45px;
  width: 100%;
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .form {
    flex-direction: column;
    height: auto;
  }
}

@media screen and (max-width: 660px) {
  .login-page {
    width: 90%;
  }
}

.error-msg{
  color: red;
}