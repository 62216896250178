/* * {
    box-sizing: border-box;
  }
  body, h1, h2, h3, h4, h5, h6, p, ul, ol, li, figure, figcaption, blockquote, dl, dd {
    margin: 0;
    padding: 0;
  } */

.card-wrapper {
	width: 500px;
	border: 1px solid #00000038;
	position: relative;
}
.photo-sec {
	background-image: url('../../../assets/images/pdf/group.svg');
	background-size: 100%;
	background-position: bottom;
	background-repeat: no-repeat;
	display: flex;
	justify-content: space-between;
	height: 300px;
	gap: 10px;
	padding: 10px;
}
.photo-sec img {
	width: 100%;
}
.photo-sec > div {
	width: 155px;
}
.header-img {
	display: flex;
	justify-content: space-between;
	margin: 20px 10px;
}
.header-img .sunnahLogo {
	width: 70px;
	height: 80px;
}
.header-img .masjidLogo {
	width: 80px;
	height: 30px;
}
.photo-sec .photo {
	display: flex;
	align-items: center;
	width: 150px;
	margin-top: 60px;
	height: 175px;
	border: 1px solid white;
}
.photo-sec .photo img {
	border: 1px solid #fff;
}
.data-sec {
	padding: 15px;
}
.data-sec .mutakif-bed {
	display: flex;
	justify-content: space-between;
}
.data-sec .mutakif-bed .number,
.personal-data > div p:nth-child(3),
.signature-sec > p {
	font-weight: bold;
}
.personal-data {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	color: black;
}
.personal-data span {
	font-weight: bolder !important;

	font-size: 18px;
	text-transform: uppercase;
}
.personal-data > div p {
	margin-bottom: 0;
	font-weight: bolder !important;
	font-size: 18px;
	text-transform: uppercase;
}
.personal-data > div {
	display: flex;
	gap: 7px;
	margin-bottom: 10px;
}
.signature-sec {
	height: 100px;
	position: relative;
	background-image: url('../../../assets//images/pdf/bg-bottom.svg');
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
}
.signature-sec .tar-tex {
	font-weight: 600;
	color: black;
	font-size: 17px;
	position: absolute;
	bottom: 40px;
	right: 10px;
}
.signature-sec .tex {
	position: absolute;
	bottom: 20px;
	right: 10px;
	font-weight: bolder;
	color: black;
	font-size: 17px;
}

.itikaaf-card {
	width: 264px;
	height: 378px;
	/* border: 1px solid #000; */
	position: relative;
	/* background-image: url("../../../assets//images/pdf/ItekafBlack.jpg"); */
	/* background-image: url("../../../assets//images/pdf/ItekafYellow.jpg"); */
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
}

.itikaaf-card .photo {
	position: absolute;
	width: 80px;
	height: 86px;
	top: 123px;
	left: 87px;
}

.itikaaf-card .photo img {
	width: 100%;
	height: 100%;
	object-fit: fill;
}

.itikaaf-card .item {
	position: absolute;
	margin: 0;
}

.itikaaf-card .user-id {
	bottom: 123px;
	left: 17px;
	color: black;
	font-size: 14px;
	width: 218px;
	text-align: center;
	overflow-wrap: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 1.2;
}

.itikaaf-card .seat-id {
	bottom: 90px;
	/* left: 156px; */
	left: 17px;
	width: 218px;
	text-align: center;
	color: black;
	font-size: 12px;
}

.itikaaf-card .seat-no {
	bottom: 68px;
	/* left: 156px; */
	left: 17px;
	width: 218px;
	text-align: center;
	color: black;
	font-size: 12px;
}

.itikaaf-card .city {
	bottom: 42px;
	/* left: 80px; */
	left: 17px;
	width: 218px;
	text-align: center;
	color: black;
	font-size: 12px;
}

.itikaaf-card .contact {
	bottom: 25px;
	/* left: 80px; */
	left: 17px;
	width: 218px;
	text-align: center;
	color: black;
	font-size: 12px;
}
